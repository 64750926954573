import React from 'react';
import styles from './Select.module.scss';
import { LocationDto, LocationType } from '@models/locations';
import { AxiosResponse } from 'axios';
import clsx from 'clsx';
import TypedSelect from '@components/inputs/TypedSelect';

export type Props<T extends boolean> = {
	onChange: (selectedOptions: T extends true ? LocationDto[] : LocationDto) => void;
	selectedOptions?: T extends true ? LocationDto[] : LocationDto;
	loadingMessage?: string;
	noOptionsMessage?: string;
	placeholder?: string;
	fetch: (term: string) => Promise<AxiosResponse<LocationDto[]>>;
	isMulti: T;
	initializeWithEmptyTerm?: boolean;
	name?: string | string[];
	isClearable: boolean;
	onTermChanged?: (term: string) => void;
	onBlur?: (e: React.FocusEvent<HTMLElement>) => void;
	onFocus?: (e: React.FocusEvent<HTMLElement>) => void;
	isOptionDisabled?: (option: LocationOption) => boolean;
	required?: boolean;
	disabled?: boolean;
}

export type State = {
	isFetching: boolean;
	availableOptions: LocationDto[];
}

export type LocationOption = LocationDto & { isChildLabel?: boolean; isParentLabel?: boolean };

const LocationSelect = <T extends boolean>(props: Props<T>) => {

	const getLocationLabel = (location: LocationOption): JSX.Element => {

		if (!location) {
			return null;
		}

		const text = [];

		let selectIconClassName = `${styles.selectIcon}`;
		if (location.type == LocationType.Airport) {
			selectIconClassName += ' icon-airport';
		} else {
			selectIconClassName += ' icon-globe';
		}

		let index = 0;
		text.push(<i key={index++} className={styles.selectCaption}>{location.codeIata}</i>);
		text.push(<b key={index++}>{location.name}</b>);

		text.push(', ');

		// City or country.
		if (location?.parent?.name != null) {
			text.push(location?.parent?.name);
		}

		// Country.
		if (location?.parent?.parent?.name != null) {
			text.push(', ');
			text.push(location?.parent?.parent?.name);
		}

		return <span className={clsx(styles.selectLabel)}><i className={selectIconClassName} /><span
			className={styles.selectText}>{text}</span></span>;
	};

	return <TypedSelect
		name={props.name}
		disabled={props.disabled}
		isMulti={props.isMulti}
		fetch={term => props.fetch(term || '')}
		getOptionLabel={getLocationLabel}
		getOptionValue={option => option.id}
		isClearable={props.isClearable}
		onChange={props.onChange}
		loadingMessage={props.loadingMessage}
		onBlur={e => props?.onBlur && props?.onBlur(e)}
		onFocus={e => props?.onFocus && props?.onFocus(e)}
		isOptionDisabled={option => props?.isOptionDisabled && props?.isOptionDisabled(option)}
		onTermChanged={props.onTermChanged}
		noOptionsMessage={props.noOptionsMessage}
		required={props.required}
		initializeWithEmptyTerm={props.initializeWithEmptyTerm}
		placeholder={props.placeholder}
		selectedOptions={props.selectedOptions}
	/>;
};

export default LocationSelect;