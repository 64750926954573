import React, { useEffect, useState } from 'react';
import { components, OptionProps } from 'react-select';
import Async from 'react-select/async';
import SelectStyles from '@components/select/components/SelectStyles';
import DropdownIndicator from '@components/select/components/DropdownIndicator';
import { AxiosResponse } from 'axios';
import clsx from 'clsx';
import { useDebouncedCallback } from "use-debounce";
import Tippy from "@tippyjs/react";
import { IPagingWrapper } from '@models/entityNavigation/IPagingWrapper';

interface IGetOptionLabel<T> {
    getOptionLabel: (option: T) => JSX.Element;
}

export interface TypedSelectProps<T, M extends boolean, P extends boolean> extends IGetOptionLabel<T> {
    onChange: (selectedOptions: M extends true ? T[] : T) => void;
    selectedOptions?: M extends true ? T[] : T;
    loadingMessage?: string;
    noOptionsMessage?: string;
    placeholder?: string;
    pageSize?: number;
    fetch?: (term: string) => Promise<AxiosResponse<T[]>>;
    fetchPartial?: (term: string, pageNumber: number) => Promise<AxiosResponse<IPagingWrapper<T>>>;
    isMulti: M;
    isPartialLoading?: P;
    initializeWithEmptyTerm?: boolean;
    name?: string | string[];
    isClearable: boolean;
    onTermChanged?: (term: string) => void;
    onBlur?: (e: React.FocusEvent<HTMLElement>) => void;
    onFocus?: (e: React.FocusEvent<HTMLElement>) => void;
    isOptionDisabled?: (option: T) => boolean;
    required?: boolean;
    disabled?: boolean;
    getOptionValue: (option: T) => string;
    minTermLength?: number;
}

export type State<T> = {
    isFetching: boolean;
    availableOptions: T[];
};

const nInput = <T extends {}>(isMulti: boolean, val: T[] | T): T[] | T => {
    if (isMulti) {
        if (val == null) {
            return [];
        }
        if (!Array.isArray(val)) {
            val = [val];
        }
        return val;
    }
    if (Array.isArray(val)) {
        if (val.length > 0) {
            return val[0];
        } else {
            return [];
        }
    }
    if (val == null) {
        return [];
    }
    return val;
};

const TypedSelect = <T extends {}, M extends boolean, P extends boolean>(props: TypedSelectProps<T, M, P>) => {
    const [term, setTerm] = useState('');
    const [noData, setNoData] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);

    const MultiValue = properties => {
        return (
            components.MultiValue && (
                <components.MultiValue {...properties}>
                    {props.getOptionLabel(properties.data)}
                </components.MultiValue>
            )
        );
    };

    const SingleValue = properties => {
        return (
            components.SingleValue && (
                <components.SingleValue {...properties}>
                    {props.getOptionLabel(properties.data)}
                </components.SingleValue>
            )
        );
    };

    //@ts-ignore 1
    const Option = <T extends {}>({ ...properties }: OptionProps<T> & IGetOptionLabel<T>) => {
        return (
            components.Option && (
                <components.Option {...properties}>
                    {props.getOptionLabel(properties.data)}
                </components.Option>
            )
        );
    };

    const [isFetching, setFetching] = useState(props.initializeWithEmptyTerm);
    const [availableOptions, setAvailableOptions] = useState<T[]>([]);

    let names: string[] = [];

    if (props.name) {
        if (Array.isArray(props.name)) {
            names = props.name;
        } else {
            names = [props.name];
        }
    }

    const search = async (term: string, pageNumber: number): Promise<T[]> => {
        setFetching(true);
        try {
            if (props.isPartialLoading) {
                const { data } = await props.fetchPartial(term, pageNumber);
                const result = data.items || [];
                setNoData(result.length == 0);
                return result;
            }

            const { data } = await props.fetch(term || '');
            return data || [];
        } finally {
            setFetching(false);
        }
    };

    const fetch = async (searchTerm: string, pageNumber: number, callback: (options: T[]) => void = null): Promise<T[]> => {
        const x = await search(searchTerm, pageNumber);

        const items = props.isPartialLoading ? [...availableOptions, ...x] : x;
        setAvailableOptions(items);
        if (callback != null) {
            callback(items);
        }

        return x;
    };

    useEffect(() => {
        fetch(term, pageNumber);
    }, [pageNumber]);

    const searchDebounced = useDebouncedCallback((searchTerm, callback: (options: T[]) => void = null) => {
        if (searchTerm.length < props.minTermLength && (!props.initializeWithEmptyTerm || availableOptions.length == 0)) {
            if (callback != null) {
                callback(availableOptions);
            }
        } else {
            fetch(searchTerm, pageNumber, callback);
        }
    }, 500);

    const getInputValue = () => {
        return JSON.stringify(nInput(props.isMulti, props.selectedOptions));
    };

    useEffect(() => {
        if (props.initializeWithEmptyTerm) {
            searchDebounced('');
        }
    }, []);

    return (
        <Tippy
            content={props.placeholder}
            placement={'left'}
            trigger={'focusin'}
        >
            <>
                <Async
                    isDisabled={props.disabled}
                    className={props.required == true ? 'select-required' : null}
                    isClearable={props.isClearable}
                    components={{ DropdownIndicator, Option, MultiValue, SingleValue }}
                    styles={{ ...SelectStyles, singleValue: p => ({ ...p, paddingLeft: 0 }) }}
                    isLoading={isFetching}
                    options={availableOptions}
                    value={props.selectedOptions}
                    isMulti={props.isMulti}
                    getOptionValue={props.getOptionValue}
                    placeholder={props.placeholder}
                    loadOptions={(term, cb) => {
                        term = (term || '').trim();
                        setTerm(term);
                        searchDebounced(term, cb);
                    }}
                    defaultOptions={availableOptions || []}
                    loadingMessage={() => props.loadingMessage}
                    noOptionsMessage={() => props.noOptionsMessage}
                    onChange={selectedOptions => {
                        if (props.onChange != null) {
                            props.onChange(selectedOptions as M extends true ? T[] : T);
                        }

                        if (names?.length > 0) {
                            const inputs = Array
                                .from(document.querySelectorAll("." + names.join(".")));
                            inputs.forEach(x => (x as any).click());
                        }
                    }}
                    onBlur={e => props?.onBlur && props.onBlur(e)}
                    onFocus={e => props?.onFocus && props.onFocus(e)}
                    isOptionDisabled={option => props?.isOptionDisabled && props.isOptionDisabled(option)}
                    onMenuScrollToBottom={() => !noData && setPageNumber(pageNumber + 1)}
                />
                {
                    props.name ?
                        names.map((x, i) => <input
                            key={i}
                            className={clsx('invisible', ...names)}
                            defaultValue={getInputValue() || ""}
                            name={x}
                        />)
                        : null
                }
            </>
        </Tippy>
    );
};

export default TypedSelect;