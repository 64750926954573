// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ReactComponent as UploadCloudSvg } from '@assets/svg/upload-cloud.svg';
import AppModal from '@components/AppModal';
import styles from '@components/Upload.module.scss';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AirwaybillsService from '@services/AirwaybillsService';
import i18next from 'i18next';
import { AirwaybillCopyType } from '@models/awbs/awbsModels';
import AuthorizedHttpClient from '@core/AuthorizedHttpClient';

type AwbDownloadModalProps = {
	isOpen: boolean;
	isAwb: boolean;
	airwaybillId: string;
	onClickClose: () => void;
};

type CopyOption = { label: string; value: AirwaybillCopyType };

const AwbDownloadModal = (props: AwbDownloadModalProps) => {
	const { t } = useTranslation();

	const copyOptions: CopyOption[] = useMemo(() => {
		return [
			{
				value: AirwaybillCopyType.Original1ForIssuingCarrier,
				label: t('printAwbToPdf.original1ForIssuingCarrier'),
			},
			{ value: AirwaybillCopyType.Original2ForConsignee, label: t('printAwbToPdf.original2ForConsignee') },
			{ value: AirwaybillCopyType.Original3ForShipper, label: t('printAwbToPdf.original3ForShipper') },
			{ value: AirwaybillCopyType.Copy4DeliveryReceipt, label: t('printAwbToPdf.copy4DeliveryReceipt') },
			{ value: AirwaybillCopyType.Copy5ExtraCopy, label: t('printAwbToPdf.copy5ExtraCopy') },
			{ value: AirwaybillCopyType.Copy6ExtraCopy, label: t('printAwbToPdf.copy6ExtraCopy') },
			{ value: AirwaybillCopyType.Copy7ExtraCopy, label: t('printAwbToPdf.copy7ExtraCopy') },
			{ value: AirwaybillCopyType.Copy8ForAgent, label: t('printAwbToPdf.copy8ForAgent') },
		];
	}, [i18next.language]);

	const [selectedCopyOptions, setSelectedCopyOptions] = useState<CopyOption[]>([]);
	const [extraCopyCount, setExtraCopyCount] = useState(0);
	const [isDownloading, setDownloading] = useState(false);

	const getCurrentDate = (): string => {
		const date = new Date();
		return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}-${date.getSeconds()}`;
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	function b64EncodeUnicode(str) {
		return window.btoa(unescape(encodeURIComponent(str)));
	}

	const onClickDownload = async () => {
		setDownloading(true);

		const url = new AirwaybillsService().getPdfFileUrl(
			props.airwaybillId,
			selectedCopyOptions.map((x) => x.value),
			extraCopyCount
		);

		const fileName = `${props.isAwb ? 'awb' : 'hawb'}-${getCurrentDate()}.pdf`;
		const { data: blob } = await AuthorizedHttpClient.get(url, { responseType: 'blob' });
		const anchor = document.createElement('a');

		const objectUrl = window.URL.createObjectURL(blob);

		anchor.href = objectUrl;
		anchor.download = fileName;
		anchor.click();
		anchor.remove();
		window.URL.revokeObjectURL(objectUrl);

		setDownloading(false);
	};

	const renderCopyOptions = () => {
		const renderCols = (arr: CopyOption[]) =>
			arr.map((copyOption, i) => {
				return (
					<div key={i}>
						<label className="form-check-label">
							<input
								type="checkbox"
								onChange={(e) => {
									if (e.target.checked) {
										setSelectedCopyOptions([...selectedCopyOptions, copyOption]);
									} else {
										setSelectedCopyOptions(
											selectedCopyOptions.filter((x) => x.value != copyOption.value)
										);
									}
								}}
							/>{' '}
							{copyOption.label}
						</label>
					</div>
				);
			});

		return (
			<div className="d-flex">
				<div className="d-flex flex-column">{renderCols(copyOptions.filter((x, i) => i < 4))}</div>
				<div className="d-flex flex-column pl-3">{renderCols(copyOptions.filter((x, i) => i >= 4))}</div>
			</div>
		);
	};

	return (
		<AppModal
			isOpen={props.isOpen}
			body={
				<div className={styles.modalBody}>
					<div className={styles.title}>{t('printAwbToPdf.printToPdf')}</div>
					{renderCopyOptions()}
					<div className="mt-3 form-inline">
						<label>{t('printAwbToPdf.extraCopies')} &nbsp;</label>
						<input
							className="form-control"
							type="number"
							onChange={(e) => {
								const val = parseInt(e.target.value);
								if (val >= 0) {
									setExtraCopyCount(val);
								}
							}}
							value={extraCopyCount}
						/>
					</div>
					<div className="text-center mt-4">
						<button
							className="btn btn-primary"
							onClick={(e) => {
								e.preventDefault();
								onClickDownload();
							}}
							disabled={isDownloading || (selectedCopyOptions.length == 0 && extraCopyCount == 0)}
						>
							{t('printAwbToPdf.downloadPdf')}
						</button>
					</div>
				</div>
			}
			onClickCloseButton={() => {
				props.onClickClose();
				setExtraCopyCount(0);
				setSelectedCopyOptions([]);
			}}
		/>
	);
};

export default AwbDownloadModal;
