import * as React from 'react';
import { Field, FieldArray, FieldProps, FormikProps, getIn } from 'formik';
import { Button, ButtonGroup, Input } from 'reactstrap';
import styles from './style.module.scss';
import InputNumber from '@components/InputNumber';
import { useTranslation } from 'react-i18next';
import nameof from 'ts-nameof.macro';
import { CreateCustomerRequestModel } from '@models/customerRequests/formModels';
import { nullIfNan } from '@helpers/Numbers';
import { Button as Btn } from '@root/components';
import { ReactComponent as IconAdd } from '@material-design-icons/svg/round/add.svg';
import { ReactComponent as IconDelete } from '@material-design-icons/svg/round/delete.svg';
import { ReactComponent as IconInfo } from '@material-design-icons/svg/round/info.svg';
import FieldError from '@scenes/customerRequest/searchForm/components/FieldError';
import { recalculateCargoDetails } from '@helpers/customerSearchFormHelpers';
import cn from 'classnames';
import Tippy from '@tippyjs/react';
import { newDimension } from '@scenes/request/constants';

interface Props {
  formikProps: FormikProps<CreateCustomerRequestModel>;
  isTotalWeightTyped: boolean;
}

type ColumnLabel = {
  text: string;
  isIcon?: boolean;
}

const Dims = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { formikProps } = props;

  const labels: ColumnLabel[] = [
    { text: 'request.items' },
    { text: 'request.itemLength' },
    { text: 'request.itemWidth' },
    { text: 'request.itemHeight' },
    { text: 'request.itemWeight', isIcon: true },
    { text: 'request.weightType' },
    { text: 'actions' },
  ];

  const onFocusHandle = (e) => {
    if (e.target.value === '0')
      e.target.value = '';
  };

  return (
    <FieldArray
      name={nameof.full<CreateCustomerRequestModel>(x => x.cargoDimensions)}
      render={({ push, remove }): JSX.Element => (
        <div className={styles.container}>
          <div className={styles.header}>
            <span className={styles.tableHeaderText}>Таблица габаритов</span>
          </div>

          {formikProps?.values?.cargoDimensions?.length > 0 &&
            <div className={styles.labels}>
              {labels.map((l, index) =>
                <span className={styles.label} key={index}>
                                    {t(l.text)}
                  {l.isIcon &&
                    <Tippy content={props.isTotalWeightTyped
                      ? t('request.dimensions.enterWeightSeparatly')
                      : t('request.dimensions.enterTotalWeight')}>
                      <IconInfo className={styles.iconInfo} fill='currentColor' />
                    </Tippy>
                  }
                                </span>,
              )}
            </div>
          }

          <div className={styles.rows}>
            {formikProps?.values?.cargoDimensions?.map((cargoDimension, index) => {
                const fieldPath = `${nameof.full<CreateCustomerRequestModel>(x => x.cargoDimensions)}[${index}]`;

                return (
                  <div key={index} className={styles.row}>
                    <div className={styles.col}>
                                            <span className={cn(styles.label, styles.labelMobile)}>
                                                {t('request.items')}
                                            </span>
                      <div className={styles.inputMobile}>
                        <Field name={`${fieldPath}.amount`}>
                          {({ field, form }: FieldProps) =>
                            <InputNumber
                              id={field.name}
                              hasError={
                                getIn(form.errors, field.name) && getIn(form.touched, field.name)
                              }
                              {...field}
                              onBlur={form.setFieldTouched}
                              onChange={(name, value) => {
                                let val: any = value;
                                if (val != null) {
                                  val = nullIfNan(parseInt(val)) || '';
                                }
                                formikProps.setFieldValue(field.name, val);
                              }}
                            />
                          }
                        </Field>
                      </div>
                    </div>
                    <div className={styles.col}>
                                            <span className={cn(styles.label, styles.labelMobile)}>
                                                {t('request.itemLength')}
                                            </span>
                      <div className={styles.inputMobile}>
                        <Field name={`${fieldPath}.length`}>
                          {({ field, form }: FieldProps) => (
                            <div className='inputControl'>
                              <Input
                                onFocus={e => onFocusHandle(e)}
                                placeholder={t('units.cm')}
                                className={getIn(form.errors, field.name) && getIn(form.touched, field.name) ? 'inputControlError' : ''}
                                {...field}
                              />
                              <FieldError
                                error={form.errors[field.name] as string}
                                isTouched={form.touched[field.name] as boolean}
                              />
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className={styles.col}>
                                            <span className={cn(styles.label, styles.labelMobile)}>
                                                {t('request.itemWidth')}
                                            </span>
                      <div className={styles.inputMobile}>
                        <Field name={`${fieldPath}.width`}>
                          {({ field, form }: FieldProps) => (
                            <div className='inputControl'>
                              <Input
                                onFocus={e => onFocusHandle(e)}
                                placeholder={t('units.cm')}
                                className={getIn(form.errors, field.name) && getIn(form.touched, field.name) ? 'inputControlError' : ''}
                                {...field}
                              />
                              <FieldError
                                error={form.errors[field.name] as string}
                                isTouched={form.touched[field.name] as boolean}
                              />
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className={styles.col}>
                                            <span className={cn(styles.label, styles.labelMobile)}>
                                                {t('request.itemHeight')}
                                            </span>
                      <div className={styles.inputMobile}>
                        <Field name={`${fieldPath}.height`}>
                          {({ field, form }: FieldProps) => (
                            <div className='inputControl'>
                              <Input
                                onFocus={e => onFocusHandle(e)}
                                placeholder={t('units.cm')}
                                className={getIn(form.errors, field.name) && getIn(form.touched, field.name) ? 'inputControlError' : ''}
                                {...field}
                              />
                              <FieldError
                                error={form.errors[field.name] as string}
                                isTouched={form.touched[field.name] as boolean}
                              />
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className={styles.col}>
                                            <span className={cn(styles.label, styles.labelMobile)}>
                                                {t('request.itemWeight')}
                                            </span>
                      <div className={styles.inputMobile}>
                        <Field name={`${fieldPath}.weight`}>
                          {({ field, form }: FieldProps) => (
                            <div className='inputControl'>
                              <Input
                                disabled={props.isTotalWeightTyped}
                                onFocus={e => onFocusHandle(e)}
                                placeholder={t('units.kg')}
                                className={getIn(form.errors, field.name) && getIn(form.touched, field.name) ? 'inputControlError' : ''}
                                {...field}
                              />
                              <FieldError
                                error={form.errors[field.name] as string}
                                isTouched={form.touched[field.name] as boolean}
                              />
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className={styles.col}>
                                            <span className={cn(styles.label, styles.labelMobile)}>
                                                {t('request.weightType')}
                                            </span>
                      <div className={styles.inputMobile}>
                        <Field name={`${fieldPath}.isTotalWeight`}>
                          {({ field, form }: FieldProps) => (
                            <ButtonGroup size='sm' style={{ width: '100%' }}>
                              <Button
                                disabled={props.isTotalWeightTyped}
                                color='primary'
                                outline={field.value}
                                onClick={() => {
                                  form.setFieldValue(field.name, false);
                                  recalculateCargoDetails(formikProps);
                                }}>
                                На место
                              </Button>
                              <Button
                                disabled={props.isTotalWeightTyped}
                                color='primary'
                                outline={!field.value}
                                onClick={() => {
                                  form.setFieldValue(field.name, true);
                                  recalculateCargoDetails(formikProps);
                                }}
                              >Общий</Button>
                            </ButtonGroup>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className={styles.col}>
                      <Btn
                        type='text'
                        icon={<IconDelete fill='currentColor' />}
                        variant='error'
                        onClick={() => remove(index)}
                      />
                    </div>
                  </div>
                );
              },
            )}
          </div>
          <div className={styles.btn}>
            <Btn
              type='fill'
              icon={<IconAdd fill='currentColor' />}
              variant='primary'
              onClick={() => push(newDimension)}
            />
          </div>
        </div>
      )}
    />
  );
};

export default Dims;
