import React, { useEffect, useMemo, useState } from 'react';
import { AirwaybillDto } from '@models/awbs/awbsModels';
import {
    clearCurrentAirwaybill,
    getAwbById,
    getNeighborOrChildrenAirwaybillsById,
    State as AirwaybillState,
    updateAwb
} from '@store/airwaybills/airwaybillsStore';
import { useDispatch, useSelector } from 'react-redux';
import { getPromise } from '@helpers/reduxHelpers';
import { getValidationErrors } from '@helpers/responseHelpers';
import { ApplicationState } from '@root/store';
import { useTranslation } from 'react-i18next';
import BackToViewPanel from './components/BackToViewPanel';
import { GetSaleDto } from '@models/customerRequests/customerRequestModels';
import { useHistory } from 'react-router';
import styles from './AwbUpdatePage.module.scss';
import nameof from 'ts-nameof.macro';
import { RecognizeControl } from '@scenes/awb/components/edit/recognizeControl';
import { updateHawb } from '@store/airwaybills/houseAirwaybillsStore';
import { AirWaybillEditor } from '@scenes/customerApplication/awb/components/airWaybillEditor';
import { useFormikContext } from 'formik';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import FhlPage from '@scenes/customerApplication/awb/FhlPage';
import FwbPage from '@scenes/customerApplication/awb/FwbPage';
import AppModal from '@components/AppModal';
import clsx from 'clsx';
import HouseAirwaybillsService from '@services/HouseAirwaybillsService';
import AirwaybillsService from '@services/AirwaybillsService';
import AuthorizedHttpClient from '@core/AuthorizedHttpClient';
import AwbDownloadModal from '@scenes/customerApplication/awb/components/AwbDownloadModal';
import { Button as CbButton } from '@root/components';

type Props = {
    airwaybillId: string;
    sale?: GetSaleDto;
    showFwbButton?: boolean;
    onClickBack: () => void;
    onSuccessSave: () => void;
};

const AwbUpdatePage = (props: Props) => {
    const [isFwbModalOpen, setIsFwbModalOpen] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
    const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
    const { t, i18n } = useTranslation();
    const { airwaybill, airwaybillDetails } = useSelector<ApplicationState, AirwaybillState>(x => x.airwaybills);
    const isMaster = useMemo(() => airwaybillDetails == null || airwaybillDetails.isMaster, [airwaybillDetails]);

    const dispatch = useDispatch();

    useEffect(() => {
        if (props.airwaybillId == airwaybill?.id) {
            return;
        }

        dispatch(getAwbById(props.airwaybillId));
        dispatch(getNeighborOrChildrenAirwaybillsById(props.airwaybillId));
        return () => {
            dispatch(clearCurrentAirwaybill());
        };
    }, [props.airwaybillId]);

    useEffect(() => {
        setIsDisabled(airwaybill && airwaybill.isFwbSent);
    }, [airwaybill]);

    const onSubmit = async (model: AirwaybillDto, continueOnSuccess = true) => {
        let response: any;

        if (isMaster)
            response = await getPromise(dispatch(updateAwb({ airwaybill: model })));
        else
            response = await getPromise(dispatch(updateHawb({
                model: {
                    houseAirwaybill: {
                        ...model,
                        parentAirwaybillId: airwaybillDetails.parentId
                    }
                }
            })));

        if (response.isError) {
            if (response.fields) {
                return getValidationErrors(response, true);
            } else {
                let errorMessage = response.message;

                if (i18n.exists(`exceptions.${response.message}`)) {
                    errorMessage = t(`exceptions.${response.message}`);
                } else if (i18n.exists(`validation.${response.message}`)) {
                    errorMessage = t(`validation.${response.message}`);
                }

                toast.error(errorMessage);
            }
        } else if (continueOnSuccess) {
            props.onSuccessSave();
        }

        return [];
    };

    const history = useHistory();
    const openAwb = (id: string) => {
        const awbId = id;
        if (awbId != airwaybill.id) {
            history.push(`/awb/edit/${awbId}`);
        }
    };

    const createHouseAwb = () => {
        history.push(`/awb/create/${airwaybill.id}`);
    };

    const deleteHouseAwb = () => {
        const service = new HouseAirwaybillsService();
        props.onClickBack();
        service
            .remove(props.airwaybillId)
            .then(() => {
                toast.success(t('awb.deletedTemplate', { awbNumber: airwaybill.airwaybillNumber }));
            });
    };

    const EditAwbButton = () => {
        const context = useFormikContext<AirwaybillDto>();

        return <a
            className="btn btn-primary"
            type={'button'}
            onClick={(e) => {
                e.preventDefault();
                setIsDisabled(false);
                context.setFieldValue(nameof.full<AirwaybillDto>(x => x.isFwbSent), false);
            }}>
            {t('awb.edit')}
        </a>;
    };

    const SaveAwbButton = () => {
        const context = useFormikContext<AirwaybillDto>();

        return <a
            className="btn btn-primary"
            onClick={(e) => {
                e.preventDefault();
                context.submitForm()
                    .then(() => {
                        const messages = document.querySelectorAll('.validationMessage');
                        if (messages.length > 0) {
                            messages[0].scrollIntoView({ block: 'center' });
                        }
                    });
            }}
        >{t('awb.save')}</a>;
    };

    const ShowButtonWithPreSave = (props: { title: string; onClick: () => void }) => {
        const context = useFormikContext<AirwaybillDto>();

        return <Button type="button"
                       color="primary"
                       onClick={(e) => {
                           e.preventDefault();

                           context.validateForm()
                               .then((errors) => {
                                   if (Object.keys(errors).length > 0) {
                                       toast.error(t('awb.hasErrors'));
                                       return;
                                   }

                                   if (context.dirty) {
                                       // form has been changed => save it before Modal open
                                       onSubmit(context.values, false)
                                           .then(() => props.onClick());
                                   } else {
                                       // open modal without saving
                                       props.onClick();
                                   }
                               });
                       }}
        >
            {t(props.title)}
        </Button>;
    };

    return <div>
        <BackToViewPanel onClickBack={props.onClickBack}>
            <div className={styles.hawb}>
                <CbButton
                    type="outline"
                    variant="primary"
                    onClick={createHouseAwb}
                >
                    {t('awb.createNewHawb')}
                </CbButton>
                <RecognizeControl
                    onRecognize={id => openAwb(id)}
                    parentId={airwaybill?.id}
                    buttonClassName={styles.button}
                    buttonTitle={t('awb.recognizeNewHawb')}
                />
            </div>
        </BackToViewPanel>

        <h2>{t(isMaster ? 'awb.editAwb' : 'awb.editHawb')}</h2>

        <AirWaybillEditor
            disabled={isDisabled}
            onSubmit={onSubmit}
            airwaybillId={props.airwaybillId}
            airlineIataPrefix={props?.sale?.airlineIataPrefix}>
            <div className={styles.awbButtons}>
                {isDisabled && <EditAwbButton />}
                {!isDisabled && <SaveAwbButton />}
                {props.showFwbButton && !isDisabled &&
                    <ShowButtonWithPreSave title={isMaster ? 'awb.sendFwb' : 'awb.sendFhl'}
                                           onClick={() => setIsFwbModalOpen(true)} />}
                <ShowButtonWithPreSave title={t('awb.print')} onClick={async () => {
                    const service = new AirwaybillsService();
                    const url = service.getPdfFileUrl(props.airwaybillId);
                    const { data: blob } = await AuthorizedHttpClient.get(url, { responseType: 'blob' });

                    const objectUrl = window.URL.createObjectURL(blob);

                    const newTab = window.open(objectUrl, '_blank');
                    newTab.onload = function() {
                        newTab.print();
                    };
                }} />
                <ShowButtonWithPreSave title={t('awb.download')} onClick={async () => setIsDownloadModalOpen(true)} />
                {!isMaster && <a className={clsx('btn btn-danger')}
                                 onClick={() => setIsConfirmDeleteModalOpen(true)}>{t('awb.delete')}</a>}
                <a
                    className={'btn btn-secondary'}
                    onClick={(e) => {
                        e.preventDefault();
                        props.onClickBack();
                    }}
                >{t('awb.cancel')}</a>
            </div>
        </AirWaybillEditor>

        <AppModal
            isOpen={isFwbModalOpen}
            size="lg"
            body={
                isMaster ?
                    <FwbPage
                        airwaybillId={props.airwaybillId}
                        onSendFwbCompleted={() => {
                            toast.success(t('awb.fwbSent'));
                            setIsDisabled(true);
                            setIsFwbModalOpen(false);
                        }} />
                    :
                    <FhlPage
                        houseAirwaybillId={props.airwaybillId}
                        onSendFhlCompleted={() => {
                            toast.success(t('awb.fhlSent'));
                            setIsDisabled(true);
                            setIsFwbModalOpen(false);
                        }} />
            }
            onClickCloseButton={() => setIsFwbModalOpen(false)}
        />

        <AppModal
            isOpen={isConfirmDeleteModalOpen}
            size="lg"
            body={t('awb.confirmDelete')}
            footer={<div className={styles.awbButtons}>
                <a className={clsx('btn', 'btn-danger')} onClick={() => deleteHouseAwb()}>{t('yes')}</a>
                <a className={clsx('btn', 'btn-secondary')}
                   onClick={() => setIsConfirmDeleteModalOpen(false)}>{t('no')}</a>
            </div>}
            onClickCloseButton={() => setIsConfirmDeleteModalOpen(false)}
        />
        <AwbDownloadModal
            isOpen={isDownloadModalOpen}
            isAwb={isMaster}
            airwaybillId={props.airwaybillId}
            onClickClose={() => setIsDownloadModalOpen(false)}
        />
    </div>;
};

export default AwbUpdatePage;