import * as React from 'react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { StandardNavigation } from '@models/entityNavigation/StandardNavigation';
import { IPagingWrapper } from '@models/entityNavigation/IPagingWrapper';
import { IGetCustomerApplicationHistoryDto } from '@models/customerApplications/customerApplicationHistoryModels';
import listStyles from '@assets/list.module.scss';
import userItemsPageStyles from '@assets/userItemsPage.module.scss';
import CustomerApplicationHistoryItem from './components/CustomerApplicationHistoryItem';
import CustomerApplicationHistoryFilter from '@scenes/customerApplicationHistory/components/CustomerApplicationHistoryFilter';
import { Collapse } from 'reactstrap';
import nameof from 'ts-nameof.macro';
import SortSelect, { AvailableProperty, createSortSelectValue, SortSelectValue } from '@components/select/SortSelect';
import { SortDirection } from '@models/entityNavigation/sorting';
import Paginator from '@components/paginator/Paginator';
import { Loader } from '@components/index';
import { ItemsPerPage } from '@components/paginator/ItemsPerPage';
import PageHeader from '@components/PageHeader';
import s from './style.module.scss';
import { Settings } from '@root/constants/localSettings';
import { ApplicationState } from '@store/index';
import { updateSettingsAmount, updateSettingsPage } from '@store/localSettings';
import { actionCreators } from '@store/customerApplications/customerApplicationsHistoryStore';


const CustomerApplicationHistoryPage: React.FC = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const dispatch = useDispatch();

    const { customerApplicationsHistory, localSettings } = useSelector((state: ApplicationState) => state);

    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [sorting, setSorting] = useState<SortSelectValue | undefined>(undefined);
    const [availableSortingProperties, setAvailableSortingProperties] = useState<AvailableProperty[]>([]);

    useEffect(() => {
        const availableSortingProperties = [
            {
                label: 'Дата создания',
                propertyName: nameof.full<IGetCustomerApplicationHistoryDto>(
                    (x) => x.createTime,
                ),
            },
            {
                label: t('customerApplicationHistory.date'),
                propertyName: nameof.full<IGetCustomerApplicationHistoryDto>(
                    (x) => x.dateStartPlan,
                ),
            },
            {
                label: t('customerApplicationHistory.organizations'),
                propertyName: 'organization.name',
            },
        ];

        setAvailableSortingProperties(availableSortingProperties);
        setSorting(createSortSelectValue(availableSortingProperties[0], SortDirection.Descending));

        highlightItem();
    }, []);

    const loadData = (navigation: StandardNavigation, debounce = false) => {
        if (debounce) {
            dispatch(actionCreators.getData(navigation));
            return;
        }

        actionCreators.getData(navigation)
    }

    const onChangeCountPerPage = (countPerPage: number) => {
        dispatch(updateSettingsAmount({ key: Settings.CustomerApplications, amount: countPerPage }));
    };

    const onChangePage = (pageNum: number) => {
        dispatch(updateSettingsPage({ key: Settings.CustomerApplications, page: pageNum }));
    };

    const highlightItem = (): void => {
        let highlighItemId = null;

        if (location.hash && location.hash.length > 0) {
            highlighItemId = location.hash.substring(1);
            if (highlighItemId.length === 0) {
                highlighItemId = null;
            }
        }

        if (highlighItemId) {
            const hightlightWatcher = setInterval(() => {
                const el = document.getElementById(highlighItemId);
                if (el) {
                    el.click();
                    el.scrollIntoView(true);
                    el.scrollTop -= 10;
                    el.classList.add('highlighted');
                    clearInterval(hightlightWatcher);
                }
            }, 500);
        }
    };

    const renderItems = (pagingWrapper: IPagingWrapper<IGetCustomerApplicationHistoryDto>) => {
        if (pagingWrapper?.items == null || pagingWrapper.items.length == 0) {
            return <div className={listStyles.box}>{t('customerApplicationHistory.nothingFound')}</div>;
        }
        return pagingWrapper.items.map((x, i) => <CustomerApplicationHistoryItem key={i} data={x} />);
    };

    const toggleOpenFilters = () => {
        setIsFilterOpen((prev) => !prev);
    };

    return (
        <div className={s.itemsTable}>
            <PageHeader
                title={t('customerApplicationHistory.title')}
                additionalInfo={`${t('customerApplicationHistory.totalCount')} ${customerApplicationsHistory.pagingWrapper?.totalCount}`} />

            <div className={userItemsPageStyles.topPanel}>
                <div className={userItemsPageStyles.sortSelect}>
                    <SortSelect
                        availableProperties={availableSortingProperties}
                        onChange={(value) => setSorting(value)}
                        value={sorting}
                    />
                </div>

                <a
                    href='#'
                    onClick={(e) => {
                        e.preventDefault();
                        toggleOpenFilters();
                    }}
                    className={`${userItemsPageStyles.topPanel__filterBtn} ${isFilterOpen ? userItemsPageStyles.topPanel__filterBtn__active : ''
                        }`}
                >
                    <span>{t('customerApplicationHistory.filter')}</span>
                    <div>
                        <i className='icon-filter' />
                    </div>
                </a>
            </div>

            <Collapse isOpen={isFilterOpen}>
                <CustomerApplicationHistoryFilter
                    sorting={sorting}
                    pageNumber={localSettings.applications.page}
                    pageSize={localSettings.applications.amount}
                    onNavigation={navigation => loadData(navigation, true)}
                />
            </Collapse>

            {customerApplicationsHistory.isFetching ? (
                <Loader />
            ) : (
                <div>
                    <div className={s.table}>
                        {renderItems(customerApplicationsHistory.pagingWrapper)}
                    </div>
                    <div className={listStyles.paginator}>
                        <Paginator
                            totalResults={customerApplicationsHistory.pagingWrapper?.totalCount}
                            limitPerPage={localSettings.applications.amount}
                            currentPage={localSettings.applications.page + 1}
                            onChangePage={(p) => onChangePage(p)}
                            pageNeighbours={4}
                        />
                        <ItemsPerPage onChange={x => onChangeCountPerPage(x)}
                            value={localSettings.applications.amount} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default CustomerApplicationHistoryPage;