import React, { PropsWithChildren } from 'react';
import s from '../modals.module.scss';
import { Modal } from '@components/Modal';
import { useTranslation } from 'react-i18next';
import MultiSwitch from '@components/MultiSwitch';
import { PageHeader, TextArea } from '@root/components';

interface IModalProps {
    open: boolean;
    confirmText: string;

    onClose(boolean?): void;

    onConfirm(boolean?): void;
}

const ParseDimsModal: React.FC<PropsWithChildren<IModalProps>> = ({ open, onClose, onConfirm, confirmText, children }) => {
    const { t } = useTranslation();

    return (
        <Modal
            open={open}
            onClose={onClose}
            onConfirm={onConfirm}
            loading={false}
            confirmText={confirmText}>
            <PageHeader title={t('request.parseDims')} />
            <div style={{whiteSpace: "pre-line"}}>
                    {t('request.hint')}
            </div>
            <div className={s.modalBody}>
                {children}
            </div>
        </Modal>
    );
};

export default ParseDimsModal;
