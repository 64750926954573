import { useTranslation } from 'react-i18next';
import { ReactComponent as IconOpen } from '@material-design-icons/svg/round/visibility.svg';
import { ReactComponent as IconRefresh } from '@material-design-icons/svg/round/refresh.svg';
import { useHistory } from 'react-router';
import {
    AirportDto,
    CargoDto,
    GetCustomerRequestHistoryItemDto,
    GetCustomerRequestHistoryItemDto_LocationDto
} from '@models/customerRequests/customerRequestHistoryModels';
import { useState } from 'react';
import listStyles from '@assets/list.module.scss';
import routePopoverStyles from '@assets/routePopover.module.scss';
import styles from '@scenes/customerRequestHistory/CustomerRequestsHistoryPage.module.scss';
import { Collapse, Popover, PopoverBody } from 'reactstrap';
import * as React from 'react';
import { isoUtcDateToLocalString, isoUtcDateToShortLocalString } from '@helpers/dateHelpers';
import { ReactComponent as Airplane } from '@assets/svg/legacy/airplane.svg';
import { ReactComponent as ArrowRightShort } from '@assets/svg/legacy/arrow_right_short.svg';
import tableStyles from '@assets/table.module.scss';
import { LocationDto } from '@models/locations';
import { splitCode } from '@helpers/specialHandlingCodesHelpers';
import CustomerRequestStatusCell from '@scenes/customerRequestHistory/components/CustomerRequestStatusCell';
import clsx from 'clsx';
import Button from '@components/Button';
import cn from 'classnames';
import { Badge } from '@root/components';

type Props = {
    data: GetCustomerRequestHistoryItemDto;
};

const getCode = (airport: AirportDto, location: LocationDto, airportGroupCode: string = null): string => {
    if (airport) {
        return airport.codeIata;
    }

    if (airportGroupCode != null) {
        return airportGroupCode;
    }

    return location?.codeIata ?? '-';
};

const getDisplayName = (airport: AirportDto, location: GetCustomerRequestHistoryItemDto_LocationDto, airportGroupCode: string = null): string => {
    if (airport) {
        return `${airport.name}, ${airport.location?.cityName}, ${airport.location?.countryName}`;
    }

    if (airportGroupCode != null) {
        return airportGroupCode;
    }

    return `${location?.cityName}, ${location?.countryName}`;
};

const renderInfo = (t, model: GetCustomerRequestHistoryItemDto) => {
    if (model == null) {
        return null;
    }

    const dateCreated = model.createTime ? isoUtcDateToLocalString(model.createTime) : null;

    return (
        <div>
            <div className={listStyles.sub__title}>{t('customerRequestsHistory.general')}</div>

            <table className={tableStyles.table}>
                <tbody>
                <tr>
                    <td className={styles.colDateTimeCreated}>
                        <span className={tableStyles.title}>{t('customerRequestsHistory.searchDate')}</span>
                        <span>{dateCreated}</span>
                    </td>
                    <td>
                        <span className={tableStyles.title}>{t('customerRequestsHistory.totalItems')}</span>
                        <span>{model.cargoTotalAmount}</span>
                    </td>
                    <td>
                        <span className={tableStyles.title}>{t('customerRequestsHistory.totalWeight')}</span>
                        <span>
								{model.cargoTotalWeight} {t('units.kg')}
							</span>
                    </td>
                    <td>
                        <span className={tableStyles.title}>{t('customerRequestsHistory.totalVolume')}</span>
                        <span>
								{model.cargoTotalVolume} {t('units.m3')}
							</span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    );
};

const renderCargoes = (t, models: CargoDto[]) => {
    if (models == null || models.length == 0) {
        return null;
    }

    const tableData = models.map((x, i) => {
        return (
            <tr key={i}>
                <th>{i + 1}.</th>
                <td>
                    <span className={tableStyles.title}>{t('customerRequestsHistory.items')}</span>
                    <span>{x.amount}</span>
                </td>
                <td>
                    <span className={tableStyles.title}>{t('customerRequestsHistory.itemLength')}</span>
                    <span>
						{x.length} {t('units.cm')}
					</span>
                </td>
                <td>
                    <span className={tableStyles.title}>{t('customerRequestsHistory.itemWidth')}</span>
                    <span>
						{x.width} {t('units.cm')}
					</span>
                </td>
                <td>
                    <span className={tableStyles.title}>{t('customerRequestsHistory.itemHeight')}</span>
                    <span>
						{x.height} {t('units.cm')}
					</span>
                </td>
                <td>
                    <span className={tableStyles.title}>{t('customerRequestsHistory.itemWeight')}</span>
                    <span>
						{x.weight} {t('units.kg')}
					</span>
                </td>
            </tr>
        );
    });
    return (
        <>
            <br />
            <div className={listStyles.sub__title}>{t('customerRequestsHistory.dimensionsOfEachPackage')}</div>
            <table className={tableStyles.table}>
                <tbody>{tableData}</tbody>
            </table>
        </>
    );
};

const CustomerRequestsHistoryItem = ({ data }: Props) => {
    const [isOpen, toggleOpen] = useState(false);
    const [showRoutePopover, toggleRoutePopover] = useState(false);
    const [showExpectedShippingDatePopover, toggleExpectedShippingDatePopover] = useState(false);

    const dateStartPlan = isoUtcDateToShortLocalString(data.dateStartPlan);

    const routePopoverId = `route-${data.id}`;
    const expectedShippingDatePopoverId = `date-${data.id}`;

    const { t } = useTranslation();

    const history = useHistory();

    return (
        <>
            <div
                id={data?.id}
                className={cn(styles.itemCard, isOpen ?? listStyles.head__active)}
                onClick={() => {
                    toggleOpen(!isOpen);
                }}
            >
                <div className={styles.itemCardColumn}
                     id={expectedShippingDatePopoverId}
                     onMouseEnter={(): void => toggleExpectedShippingDatePopover(true)}
                     onMouseLeave={(): void => toggleExpectedShippingDatePopover(false)}
                >
                    {dateStartPlan}
                </div>

                <div className={styles.itemCardColumn}>
                    <Badge
                        label={data.isRealNumberFromBackOffice ? data.number : t('customerRequestsHistory.numberUpdating')}
                        color={data.isRealNumberFromBackOffice ? 'blue' : 'white'} />
                </div>

                <div className={styles.itemCardColumn}
                     onMouseEnter={(): void => toggleRoutePopover(true)}
                     onMouseLeave={(): void => toggleRoutePopover(false)}
                >
							<span id={routePopoverId}>
								<span className="pr-2">{getCode(data.sourceAirport, data.sourceLocation)}</span>
								<i className={clsx(listStyles.arrowRightIcon, `icon-arrow_right_long`)} />
								<span
                                    className="pl-2">{getCode(data.targetAirport, data.targetLocation, data.airportGroupCode)}</span>
							</span>
                </div>

                <div className={styles.itemCardColumn}>
                    <Badge label={data.specialHandlingCodes?.map((x) => splitCode(x.name).code).join(', ') || '-'}
                           color="white" />
                </div>

                <div className={styles.itemCardColumn}><CustomerRequestStatusCell statusCode={data.statusCode} /></div>

                <div className={cn(styles.colButtons, styles.itemCardColumn)}>
                    <Button
                        title={t('customerRequestsHistory.repeatSearch')}
                        icon={<IconRefresh fill="currentColor" />}
                        type="fill"
                        variant="primary"
                        onClick={() => history.push(`request/${data.id}`)} />
                    <Button
                        title={t('customerRequestsHistory.viewSearchResults')}
                        icon={<IconOpen fill="currentColor" />}
                        type="fill"
                        variant="white"
                        onClick={() => history.push(`request/search?id=${data.id}`)} />
                </div>
            </div>

            <Collapse isOpen={isOpen}>
                <div className={`${listStyles.sub} w-100`}>
                    <a
                        className={listStyles.sub__btn__minimize}
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            toggleOpen(false);
                        }}
                    >
                        {t('customerRequestsHistory.minimize')}{' '}
                        <i className={`${isOpen ? listStyles.isOpened : listStyles.isClosed} icon-arrow_top`} />
                    </a>

                    <br />
                    <br />

                    <div>{renderInfo(t, data)}</div>

                    <div>{renderCargoes(t, data?.cargoes)}</div>
                </div>
            </Collapse>

            {/* Route popover. */}
            <Popover placement="bottom" target={routePopoverId} isOpen={showRoutePopover}>
                <PopoverBody className={routePopoverStyles.popover}>
                    <div className={routePopoverStyles.header}>
                        <div className={routePopoverStyles.airplane}>
                            <Airplane />
                        </div>
                        <div className={routePopoverStyles.codes}>
                            <span>{getCode(data.sourceAirport, data.sourceLocation)}</span>
                            <ArrowRightShort />
                            <span>{getCode(data.targetAirport, data.targetLocation, data.airportGroupCode)}</span>
                        </div>
                    </div>

                    <div className={routePopoverStyles.info}>
                        <div>
                            <b>{t('customerRequestsHistory.source')}</b>
                            <div>{getDisplayName(data.sourceAirport, data.sourceLocation)}</div>
                        </div>
                        <div>
                            <b>{t('customerRequestsHistory.target')}</b>
                            <div>{getDisplayName(data.targetAirport, data.targetLocation, data.airportGroupCode)}</div>
                        </div>
                    </div>
                </PopoverBody>
            </Popover>

            {/* Expected shipping date popover. */}
            <Popover placement="top" target={expectedShippingDatePopoverId} isOpen={showExpectedShippingDatePopover}>
                <PopoverBody>{t('customerRequestsHistory.expectedShippingDate')}</PopoverBody>
            </Popover>
        </>
    );
};

export default CustomerRequestsHistoryItem;