import React, { useEffect, useMemo, useState } from "react";
import { components, OptionProps } from "react-select";
import Async from "react-select/async";
import debounce from "awesome-debounce-promise";
import SelectStyles from "@components/select/components/SelectStyles";
import DropdownIndicator from "@components/select/components/DropdownIndicator";
import { OptionTypeBase } from "react-select/src/types";
import { StandardNavigation } from "@models/entityNavigation/StandardNavigation";
import { AxiosResponse } from "axios";
import { IPagingWrapper } from "@models/entityNavigation/IPagingWrapper";
import { FilterConnection, FilterOperator, FilterValue, FilterValueCondition } from "@models/entityNavigation/filtering";
import { useTranslation } from "react-i18next";

export type Props<T> = {
    onChange(options: T[]): void;
    optionLabel: (option: T) => JSX.Element;
    selectedLabel: (option: T) => JSX.Element;
    fetch: (nav: StandardNavigation) => Promise<AxiosResponse<IPagingWrapper<T>>>;
    filterPropertyName: string;
    getUniqueOptionValue: (option: T) => string;
    value: T[];
} & Omit<
    OptionTypeBase,
    "isLoading"
    | "options"
    | "value"
    | "getOptionLabel"
    | "getOptionValue"
    | "loadOptions"
    | "onChange"
    | "defaultOptions"
    | "defaultValue"
    | "loadingOptions"
    | "noOptionsMessage"
    | "label"
>;

function StandardNavigationSelect<T>(props: Props<T>) {
    const [term, setTerm] = useState('');
    const [optionsCallback, setOptionsCallback] = useState<(options: any) => void>(null);
    const [isFetching, setFetching] = useState<boolean>(true);
    const [availableOptions, setAvailableOptions] = useState<T[]>([]);

    const MultiValue = providedProps => {
        return (
            components.MultiValue && (
                <components.MultiValue {...providedProps}>
                    {props.selectedLabel(providedProps.data)}
                </components.MultiValue>
            )
        );
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const Option = ({ ...providedProps }: OptionProps<T>) => {
        return (
            components.Option && (
                <components.Option {...providedProps}>
                    {props.optionLabel(providedProps.data)}
                </components.Option>
            )
        );
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const SingleValue = ({ ...providedProps }: OptionProps<T>) => {
        return (
            components.SingleValue && (
                <components.SingleValue {...providedProps}>
                    {props.selectedLabel(providedProps.data)}
                </components.SingleValue>
            )
        );
    };

    const { t } = useTranslation();

    const filter = useMemo(() => {
        const nav = new StandardNavigation();
        if (term != '') {
            const termFilterValue = new FilterValue(props.filterPropertyName, FilterValueCondition.Contains, term);
            const filterCn = new FilterConnection(FilterOperator.And);
            nav.filters.push(filterCn);

            filterCn.values.push(termFilterValue);
        }

        return nav;
    }, [term]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const search = (term: string, setOptions?: ((options: any) => void)) => {
        setTerm((term || '').trim());
        setOptionsCallback(setOptions);
    };

    const searchDebounced = debounce(search, 500);

    useEffect(() => {
        search("");
    }, []);

    useEffect(() => {
        let isCurrent = true;

        const fetch = async () => {
            setFetching(true);
            const { data } = await props.fetch(filter);
            if (!isCurrent) {
                return;
            }
            const opts: T[] = data?.items || [];
            setFetching(false);
            setAvailableOptions(opts);
            if (optionsCallback) {
                optionsCallback(opts);
            }
        };

        fetch();

        return () => {
            isCurrent = false;
        };
    }, [filter, optionsCallback]);

    const onChange = (options: T[]) => {

        if (options == null) {
            options = [];
        } else if (!Array.isArray(options)) {
            // Normalize for TS signature.
            options = [options];
        }

        props.onChange(options);
    };

    const selectProps = { ...props };
    delete selectProps.onChange;
    delete selectProps.value;
    delete selectProps.label;

    // Normalize value.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let val: any = props.value;
    if (!props.isMulti && Array.isArray(val)) {
        val = val[0];
    }
    if (val == undefined) {
        val = null;
    }

    return <Async
        components={{ DropdownIndicator, Option, SingleValue, MultiValue, ...props.components }}
        styles={{ ...SelectStyles, ...selectProps.styles }}
        isLoading={isFetching}
        options={availableOptions}
        value={val}
        getOptionValue={props.getUniqueOptionValue}
        getOptionLabel={x => JSON.stringify(x)}
        loadOptions={(term, setOptions) => {
            searchDebounced(term, setOptions);
        }}
        defaultOptions={availableOptions}
        loadingMessage={() => t('options.loadingOptions')}
        noOptionsMessage={() => t('options.noOptions')}
        onChange={newSelectedOptions => {
            let opts = newSelectedOptions;
            if (opts == null) {
                opts = [];
            } else if (!Array.isArray(opts)) {
                // Normalize TS signature for single value.
                opts = [opts];
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange(opts as any);
        }}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        {...selectProps as any}
    />;
}

export default StandardNavigationSelect;